<template>
  <div>
    <h4 class="text-center mb-4" v-if="horarios.length > 0">
      Agendas/Horários de {{ paciente.razao_social }}
    </h4>
    <div v-if="horarios.length > 0" class="row text-nowrap" style="gap: 10px">
      <div
        class="col border p-3 rounded shadow"
        v-for="horario of horarios"
        :key="horario.id"
      >
        <div>
          <b> <i class="fas fa-calendar"></i> Data: </b>
          {{ horario.DataAgenda | moment("DD/MM/YYYY") }}
        </div>

        <div>
          <b> <i class="fas fa-clock"></i> Horário: </b>
          {{ horario.horario }}
        </div>

        <div>
          <b> <i class="fas fa-clinic-medical"></i>Clinica: </b>
          {{ horario.ClinicaNome }}
        </div>

        <div>
          <b> <i class="fas fa-user-nurse"></i> Médico: </b>
          {{ horario.MedicoNome }}
        </div>
        <div>
          <b> <i class="fas fa-stethoscope"></i> Especialidade: </b>
          {{ horario.especialidade }}
        </div>
        <div>
          <b> <i class="fas fa-undo"></i> Retorno: </b>
          {{ horario.retorno ? "Sim" : "Não" }}
        </div>
        <div>
          <b> <i class="fas fa-check"></i> Confirmado: </b>
          {{ horario.confirmou ? "Sim" : "Não" }}
        </div>
        <div>
          <b> <i class="fas fa-user-check"></i> Compareceu: </b>
          {{ horario.compareceu ? "Sim" : "Não" }}
        </div>
      </div>
    </div>

    <div v-if="horarios.length == 0" class="h-100">
      <EmptyState class="p-3 my-auto">
        <h3>Esse paciente ainda não tem horários...</h3>
      </EmptyState>
    </div>
  </div>
</template>

<script>
import HorariosLib from "../../../../libs/HorariosLib";
import EmptyState from "../../../common/EmptyState.vue";

export default {
  name: "PacienteAgendaHOrario",
  components: {
    EmptyState,
  },
  props: {
    id_paciente: [Number, String],
    obj: [Array],
    paciente: [Object],
  },

  data() {
    return {
      horarios: [],
    };
  },
  async mounted() {
    if (this.obj) {
      this.horarios = JSON.parse(JSON.stringify(this.obj));
    } else if (this.id_paciente) {
      this.horarios = await HorariosLib.get(null, null, null, this.id_paciente);
    }
  },
};
</script>

<style></style>
